@tailwind base;
@tailwind components;
@tailwind utilities;

.react-calendar__tile.highlight {
    background-color: #00aaff;
    color: white;
}

.swal2-title-custom {
    font-size: 16px;
    color: #00aaff;
    font-weight: 700
}

.number-input {
    -moz-appearance: textfield;
}

.number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.number-input:focus {
    outline: none !important;
    box-shadow: 0 0 0 0 transparent;
}

.custom-scrollbar::-webkit-scrollbar {
    height: 8px !important;
    width: 2px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #ffffff3b;
}

.placeholder-sm::placeholder {
    font-size: 10px
}

.placeholder-lg::placeholder {
    font-size: 15px
}

.custom-shape-divider-top-1721992260 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1721992260 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 170px;
}

.custom-shape-divider-top-1721992260 .shape-fill {
    fill: #47A7FD;
}

@layer components {

    .button {
        @apply bg-bgbutton px-4 py-2 text-xs font-semibold text-white h-[45px] rounded-lg;
    }

    .flat-button {
        @apply bg-bgbutton w-full px-4 py-4 text-sm font-semibold text-white h-[45px] rounded-[10px] text-center;
    }

    .button-white-border {
        @apply bg-white w-full border border-bgbutton px-4 py-4 text-xs font-semibold text-primary-900 h-[45px] text-center rounded-[10px];
    }

    .selected {
        @apply bg-[#47A7FD] rounded-md text-white
    }

}